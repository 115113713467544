@import 'bootstrap/dist/css/bootstrap.min.css';

.project {
    position: relative;
    background-color: rgba(33, 37, 41, .25);
    max-width: 15em;
    padding: 40px 35px;
    border-radius: 10px;
    max-height: 18em;
    overflow: hidden;
}

.project:hover {
    transform: scale(1.08);
    cursor: pointer;
    transition: 200ms;
}