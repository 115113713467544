.nav__link {
    list-style: none;
}

.link {
    text-decoration: none;
    color: #fff;
}

.link:hover {
    color: #B3B3B3;
    cursor: pointer;
    transition: 200ms;
}

.dropdown-menu {
    background-color: rgba(33, 37, 41, .4);
    transition: 250ms;
}

.dropdown-item {
    color: #fff;
}

.dropdown-item:hover {
    background-color: #B3B3B3;
    color: #000;
    cursor: pointer;
}

.sun:hover {
    cursor: not-allowed;
    opacity: .8;
    transition: 200ms;
}

@media screen and (max-width: 400px) {
    .nav__link {
        display: none;
    }

    .sun {
        display: none;
    }

    .dropdown {
        display: block;
    }
}

@media screen and (min-width: 401px) {
    .dropdown {
        display: none;
    }
}